<template>
	<div class="news_banner-1">
		<div class="news_banner">
			<img :src="banner[0].bannerImg" alt="" width="100%" />
		</div>
		<el-row :gutter="20" type="flex" justify="center" class="joinus">
			<el-col :offset="0" class="shengshiqu">
				<div class="shengshiqu-title">
					<!-- 加入我们 -->{{$t('joinus.Join Us')}}
				</div>
				<div class="a11">
					<div class="form-i">

						<div class="form-i-item">
							<div class="form-i-label">{{$t('joinus.Departments')}}: </div>
							<div class="form-i-input">
								<el-select class="flable-item select" v-model="sheng" placeholder="请选择部门" @change="bm">
									<el-option :label="$t('joinus.whole')" value=""> </el-option>
									<el-option :label="item.dictLabel" :value="item.dictLabel"
										v-for="(item, index) in topStars" :key="index">
									</el-option>
								</el-select>
							</div>
							
						</div>

						<div class="form-i-item">
							<div class="form-i-label">{{$t('joinus.Locations')}}: </div>
							<div class="form-i-input">
								<el-select v-model="shengs" placeholder="请选择城市" @change="dd" class="flable-item">
									<el-option :label="$t('joinus.whole')" value=""> </el-option>
									<el-option :label="item.dictLabel" :value="item.dictLabel"
										v-for="(item, index) in topStarx" :key="index">
									</el-option>
								</el-select>
							</div>
						</div>

						<div class="form-i-item">
							<div class="form-i-label">{{$t('joinus.Job positions')}}: </div>
							<div class="form-i-input">
								<input class="input flable-item" type="text" v-model="shengx" :placeholder="$t('joinus.Please11')" />
							</div>
						</div>

					</div>

					<div class="but"><button class="btn-a" @click="getTopStar()">
							<!-- 搜索 -->{{$t('joinus.Search')}}
						</button></div>
					<!-- <div class="dizhicha">
							<label for="" class="flable">{{$t('joinus.Departments')}}: </label>
				  <el-select class="flable-item select"
				    v-model="sheng"
				    placeholder="请选择省份"
				    @change="bm"
				  >
				    <el-option label="全部" value=""> </el-option>
				    <el-option
				      :label="item.dictLabel"
				      :value="item.dictLabel"
				      v-for="(item, index) in topStars"
				      :key="index"
				    >
				    </el-option>
				  </el-select>
				</div>
				
				
				<div class="dizhicha">
							<label for="" class="flable">{{$t('joinus.Locations')}}: </label>
				  <el-select v-model="shengs" placeholder="请选择城市" @change="dd" class="flable-item">
				    <el-option label="全部" value=""> </el-option>
				    <el-option
				      :label="item.dictLabel"
				      :value="item.dictLabel"
				      v-for="(item, index) in topStarx"
				      :key="index"
				    >
				    </el-option>
				  </el-select>
				</div>
				
				
				<div class="dizhicha">
							<label for="" class="flable">{{$t('joinus.Job positions')}}</label>
				  <input
				    class="input flable-item"
				    type="text"
				    v-model="shengx"
				    placeholder=" 输入职位名称"
				  />
				</div> -->
				</div>
			</el-col>
			<!-- <button class="btn" @click="getTopStar()"> -->
			<!-- 搜索 -->
			<!-- {{$t('joinus.Search')}}</button> -->

			<el-col :span="16" :offset="0" class="address1">
				<div class="zwxq0" v-for="(item, index) in topStar" :key="index">
					<div class="zwxq">
						
						<div class="zwxq-1">
							<!-- <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
							     {{ item.name }}
							    </el-tooltip> -->
								 {{ item.name }}
						</div>
						<div class="zwxq-1-1">{{ item.code }}</div>
						<div class="zwxq-2">
							<i class="el-icon-user"></i>{{ item.nature }}
							{{ item.department }}
						</div>
						<div class="zwxq-3">
							<i class="el-icon-location"></i>{{ item.city }}
						</div>
					</div>
					<div class="an" @click="toDetails(item.code)"><!-- 查看详情 -->{{$t('joinus.View details')}}</div>
				</div>
			</el-col>
			<div class="aa1-2" v-if="showbutton" @click="getadd()">
				<!-- 加载更多 -->{{$t('joinus.More Job positions')}}
			</div>
			<div style="height: 40px"></div>
		</el-row>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			// title: "加入我们", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "锐思",
				},
				{
					name: "description",
					content: "我是description描述",
				},
			],
			link: [{
				// set link
				rel: "asstes",
				href: "www.baidu.com",
			}, ],
		},
		data() {
			return {
				sheng: "",
				shengs: "",
				shengx: "",
				topStar: "",
				topStars: "",
				topStarx: "",
				banner: [{
					bannerImg: "",
				}, ],
				pageNum: 1,
				pageSize: 6,
				showbutton: true
			};
		},
		computed:{
			language(){
				return this.$store.state.Language
			}
		},
		created() {
			window.document.title = this.$t('joinus.title')
			this.getTopStar();
			this.getBanner();
		},
		watch:{
			language(newVal,oldVal){
				window.document.title = this.$t('joinus.title')
				this.sheng = ''
				this.shengs = ''
				this.shengx = ''
				this.getTopStar()
			}
		},
		methods: {
			getBanner() {
				this.$http.get("/appBanner/getBannerList?spaceId=14").then((res) => {
					this.banner = res.data;
				});
			},
			bm(e) {
				this.getTopStar();
			},
			dd(e) {
				this.getTopStar();
			},
			toDetails(item) {
				console.log(item)
				this.$router.push({
					path: '/jobDetails',
					query: {code: item}
				});
			},
			getadd(){
				this.pageSize+=6
				this.getTopStar()
			},
			getTopStar() {
				let language = this.$store.state.Language
				let aa = encodeURIComponent(this.sheng)
				this.$http
					.get(
						`/recruit/list?name=${this.shengx}&department=${aa}&pageNum=${this.pageNum}&pageSize=${this.pageSize}&city=${this.shengs}&language=${language}`	
					)
					.then((res) => {
						this.topStar = res.rows;
						console.log(res.total)
						if(this.topStar.length >= res.total){
							this.showbutton = false
						}else{
							this.showbutton = true
						}
					});
					
					if(language=='cn'){
						this.$http.get("/dict/type/recruit_department").then(res=>{//部门中文
							this.topStars = res.data;
						})
						this.$http.get("/dict/type/recruit_city").then((res) => {//城市中文
							this.topStarx = res.data;
						});					
						
					}else{ //部门英文
						this.$http.get("/dict/type/recruit_department_en").then(res=>{ //部门英文
							this.topStars = res.data;
						})
						this.$http.get("/dict/type/recruit_city_en").then((res) => {//城市英文
							this.topStarx = res.data;
						});
					}
					
				
				
			},
		},
	};
</script>
<style lang="scss">
	.news_banner-1 {
		background-color: rgb(245, 245, 247);
	}

	.news_banner {
		align-items: center;
		text-align: center;
		width: 100%;

		p {
			padding-left: 100px;
		}
	}

	input.el-input__inner {
		background: transparent;
		border: none;
		outline: none;
		border-bottom: 1px solid #ccc;
		// color: #fff;
	}

	// input.el-input__inner::-webkit-input-placeholder {
	//   // color: #fff;
	// }
</style>
<style lang="scss" scoped>
	.el-select {
		width: 60%;
		margin-left: 5%;
	}

	.aa1-2 {
		background-color: rgb(35, 80, 199);
		height: 40px;
		// width: 155px;
		padding: 0px 10px;
		margin: auto;
		border-radius: 5px;
		margin-top: 50px;
		color: rgb(255, 255, 255);
		font-size: 18px;
		text-align: center;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.aa1-2:hover {
		background-color: rgb(255, 255, 255);
		color: rgb(35, 80, 199);
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer;
	}

	.zwxq0 {
		display: inline-block;
		width: 30%;
		border-radius: 10px;
		margin: 1.5%;

		.an {
			background-color: rgb(35, 80, 198);
			font-size: 14px;
			// font-weight: 700;
			box-sizing: border-box;
			border: 2px solid rgb(35, 80, 199);
			color: rgb(255, 255, 255);
			text-align: center;
			padding: 5px;
			transition: 0.5s;
		}
	}

	.zwxq0:hover .an {
		background-color: rgb(253, 253, 253) !important;
		font-size: 14px;
		// font-weight: 700;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		color: rgb(35, 80, 199) !important;
		text-align: center;
		padding: 5px;
		transition: 0.5s;
		cursor: pointer;
	}

	.zwxq {
		color: rgb(0, 0, 0);
		width: 100%;
		background-color: rgb(255, 255, 255);
		padding: 5%;
		box-sizing: border-box;
		// border-radius: 5px;
	}

	.zwxq-1 {
		font-size: 18px;
		
		font-weight: 700;
		height: 50px;
		line-height: 25px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp:2;  /* 设置行数 */
		-webkit-box-orient: vertical;
	}
	.zwxq-1-1{
		font-weight: 400;
		font-size: 14px;
		margin-left: 15px;
	}

	.zwxq-2 {
		font-size: 14px;
		height: 38px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp:2;  /* 设置行数 */
		-webkit-box-orient: vertical;

		i {
			font-size: 16px;
			margin-right: 15px;
		}
	}

	.zwxq-3 {
		font-size: 14px;

		i {
			font-size: 16px;
			margin-right: 15px;
		}
	}

	.a11 {
		width: 64%;
		margin: auto;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
	}

	.form-i {
		// height: 100px;
		width: 100%;
		// background-color: aqua;
		overflow: hidden;
	}
	.form-i-item{
		width: auto;
		height: 38px;
		justify-content: flex-start;
		align-items: center;
		float: left;
		display: flex;
		margin-bottom: 25px;
		padding-right: 15px;
	}
	.form-i-label{
		width: auto;
		display:inline;
		white-space: nowrap;
	}
	.form-i-input{
		width: 100%;
	}
	.flable-item{
		width: 90% !important;
	}
	.but {
		width: 100px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	@media screen and (max-width: 996px) {
		.a11 {
			width: 83.3%;
			flex-direction: column;
		}
	}
	@media screen and (max-width: 750px){
		.a11 {
			flex-direction: column;
		}
		.form-i-item{
			width: 100%;
		}
	}
	.btn-a {
		width: 100%;
		line-height: 31px;
		background: rgb(35, 80, 198);
		border: none;
		border-radius: 5px;
		font-size: 17px;
		color: #fff;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
	}

	.btn-a:hover {
		color: rgb(35, 80, 199);
		background: rgb(255, 255, 255);
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer;
	}

	.dizhicha {
		// display: flex;
		// flex: 1;
		// display: inline-block;
		// width: 28%;
		float: left;
		// width: 100%;
		width: 350px;
		height: 50px;

		span {
			padding: 0 !important;
			// margin-left: 12%;
		}
	}

	.joinus {
		position: relative;
		top: -4px;
		flex-direction: column;
		align-items: center;
	}

	.shengshiqu-title {
		color: rgb(0, 0, 0);
		box-sizing: border-box;
		padding: 80px;
		width: 100%;
		font-size: 34px;
		// font-weight: 700;
		text-align: center;
		font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
	}

	.shengshiqu {
		span {
			padding: 0px 20px;
		}
		// color: #fff;
		padding: 20px 50px;
		height: auto;
		// display: flex;
		justify-content: center;
		flex-wrap: wrap;
		// background: url(https://consumer.huawei.com/content/dam/huawei-cbg-site/greate-china/cn/support/wheretobuy/banner.jpg)
		//   no-repeat;
		// background-size: 100% 100%;
		// background-color: rgb(27, 23, 37);
	}

	.select {
		background: transparent;
	}

	.input {
		width: 60%;
		margin-left: 5%;
		line-height: 30px;
		height: 22px;
		// border-radius: 6px;
		border: none;
		border-bottom: 1px solid #ccc;
		// border: 1px solid #ccc;
		outline: none;
		// padding-top: 20px;
		background: transparent;
		// color: #fff;
	}

	// .input::-webkit-input-placeholder {
	//   // color: #fff;
	// }

	.btn {
		float: right;
		width: 15%;
		height: 35px;
		// margin: 0px 2%;
		line-height: 31px;
		background: rgb(35, 80, 198);
		border: none;
		border-radius: 5px;
		font-size: 17px;
		margin-top: 10px;
		margin-right: 1%;
		// cursor: pointer;
		color: #fff;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
	}

	.btn:hover {
		color: rgb(35, 80, 199);
		background: rgb(255, 255, 255);
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
		cursor: pointer;
	}

	.address {
		padding-top: 30px;
		display: flex;
	}

	.address-c {
		height: 400px;
		width: 70%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.address1 {
		// padding-top: 30px;
		// padding-bottom: 30px;
		padding: 10px 20px;

		// display: flex;
		div {
			// line-height: 30px;
		}
	}

	.address-c1 {
		height: 200px;
		width: 35%;
		margin-right: 49px;
		justify-content: space-between;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.title {
		font-size: 16px;
	}

	.right-detail {
		padding: 10px 20px;
		box-shadow: 0px 0px 5px #ccc;
		height: 400px;
	}

	.address-detail {
		font-size: 14px;
	}

	.right-c {
		padding: 0px 10px 10px;
		line-height: 30px;
		margin-top: 2px;
		cursor: pointer;
	}

	.b {
		font-weight: 700;
	}

	@media screen and(max-width:996px) {
		.shengshiqu {
			flex-direction: column;

			.shengshiqu-title {
				width: auto !important;
			}
		}

		.el-col-16 {
			width: 83.33%;
		}

		.address-c1 {
			width: 100%;
			margin: 0;
		}

		.btn {
			width: 82%;
			// margin: 0px 5%;
			margin-top: 20px;
			background: rgb(35, 80, 199);
			float: left;
		}
		.shengshiqu {
			height: 100%;
		}

		.zwxq0 {
			display: inline-block;
			width: 100%;
			border-radius: 10px;
			// margin: 3%;
			margin-top: 40px;
			box-sizing: border-box;
		}

		.dizhicha {
			display: inline-block;
			width: 100%;
			height: 50px;
		}

		.input {
			width: 60%;
			margin-left: 5%;
			padding-top: 0;
		}

		.address-c {
			display: none;
		}

		.right-detail {
			height: 100%;
			width: 100%;
		}

		.address {
			width: 90%;
			margin-left: 5%;
		}
	}
</style>
